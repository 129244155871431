<template>
  <b-table-simple class="table" :responsive="isSimplifiedTable" borderless>
    <thead>
      <tr>
        <th 
          v-for="(field, index) in tablefields"
          :key="index" 
          v-html="field.label"
         />
        <th>Status</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in table" :key="index">
        <td v-for="(field, index) in tablefields" :key="index">
          <b-col v-if="field.label === 'CD'">
            <multiselect
              :value="cds.find(cd => cd.value === item.expense_code)"
              @select="e => setCD(item, e.value)"
              placeholder="Selecionar"
              :options="cds"
              track-by="value"
              label="label"
              :searchable="true"
              :allow-empty="false"
              :showLabels="false"
              :showNoResults="false"
              class="with-border"
              :disabled="!item.status"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                <Ellipsis>{{ option.label }}</Ellipsis>
              </template>
              <template slot="option" slot-scope="{ option }">
                <p>{{ option.label }}</p>
              </template>
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
            </multiselect>
          </b-col>
          <b-col v-else-if="field.label === 'Unidade Medida'">
            <multiselect
              :value="unitMeasures.find(unit => unit.value === item.measurement_unit)"
              @select="e => setValue(item, 'measurement_unit', e.value)"
              placeholder="Selecionar"
              :options="unitMeasures"
              track-by="value"
              label="label"
              :searchable="true"
              :allow-empty="false"
              :showLabels="false"
              :showNoResults="false"
              class="with-border"
              :disabled="!item.status"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                  <Ellipsis>{{ option.label }}</Ellipsis>
              </template>
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
            </multiselect>
          </b-col>
          <span v-else-if="field.label === 'Cód. Simpro<br/>Cód. TUSS' && (item.tiss_code || item.tuss_code)">
            <Ellipsis>
              <p>{{ item?.simpro_code }}</p>
              <p>{{ item?.tuss_code }}</p>
            </Ellipsis>
          </span>
          <span v-else-if="field.label === 'Cód. TISS<br/>Cód. TUSS' && (item.tiss_code || item.tuss_code)">
            <Ellipsis>
              <p>{{ item?.tiss_code }}</p>
              <p>{{ item?.tuss_code }}</p>
            </Ellipsis>
          </span>
          <span v-else-if="field.key === 'item'">
            <Ellipsis>
              <p>{{ item?.item }}</p>
              <p>{{ item?.manufacturer }}</p>
            </Ellipsis>
          </span>
          <span v-else-if="field.key === 'description'">
            <Ellipsis>
              <p>{{ item?.description }}</p>
              <p>{{ item?.manufacturer }}</p>
            </Ellipsis>
          </span>
          <span v-else-if="field.monetary">
            {{ showMoneyField(item[field?.key]) }}
          </span>
          <span v-else-if="item[field?.key]">
            <Ellipsis>{{ item[field?.key] }}</Ellipsis>
          </span>
          <span v-else>-</span>
        </td>
        <td>
          <b-row>
            <b-col>
              <toggle-button
                :value="!!item.status"
                @change="e => setValue(item, 'status', e.value)"
                class="autoFillBtn"
                :sync="true"
                :height="24"
                :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
              />
            </b-col>
          </b-row>
        </td>
        <td>
          <b-button
            size="sm"
            variant="primary"
            @click="emitSave(item)"
            v-b-tooltip.hover 
            :disabled="isButtonDisabled(item)"
            :title="isButtonDisabled(item) ? 'Escolha um CD para salvar' : 'Salvar item'"
          >
            Salvar
          </b-button>
        </td>
      </tr>
    </tbody>
  </b-table-simple>
</template>

<script>
import cdsOptions from '@/components/Tiss/Data/cds'
import unitMeasures from '@/components/Tiss/Data/unitMeasures'
import { parseNumberToMoney } from '@/utils/moneyHelper'

export default {
  name: 'Table',
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Ellipsis: () => import('@/components/General/Ellipsis'),
  },
  props: {
    type: {
      type: String,
      default: 'brasindice'
    },
    table: {
      type: Array,
      default: () => []
    },
    isSimplifiedTable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      cds: cdsOptions,
      unitMeasures: unitMeasures,
      brasindiceFields: [
        { key: 'expense_code', label: 'CD', simplified: true },
        { key: 'table_reference', label: 'Tabela', simplified: true },
        { key: 'tiss_code', label: 'Cód. TISS<br/>Cód. TUSS', simplified: true },
        { key: 'item', label: 'Item<br/>Fabricante', simplified: true },
        { key: 'presentation', label: 'Apresentação', simplified: true },
        { key: 'measurement_unit', label: 'Unidade Medida', simplified: true },
        { key: 'qtde', label: 'Qtde eq.', simplified: true},
        { key: 'pmc_price', label: 'PMC', simplified: true, monetary: true}, 
        { key: 'pmc_price_fraction', label: 'PMC Fração', simplified: true, monetary: true}, 
        { key: 'pfb_price', label: 'PF', simplified: true, monetary: true}, 
        { key: 'pfb_price_fraction', label: 'PF Fração', simplified: true, monetary: true}, 
        { key: 'cod_item', label: 'Cód Item', simplified: false},
        { key: 'cod_apres', label: 'Cód Apres', simplified: false},
        { key: 'fraction', label: 'Fracio', simplified: false},
        { key: 'edition', label: 'Edição', simplified: false},
        { key: 'ipi', label: 'IPI', simplified: false},
        { key: 'pis', label: 'PIS', simplified: false},
        { key: 'ean', label: 'EAN', simplified: false},
        { key: 'updt', label: 'Edição UPDT', simplified: false}
      ],
      simproFields: [
        { key: 'expense_code', label: 'CD', simplified: true },
        { key: 'table_reference', label: 'Tabela', simplified: true },
        { key: 'simpro_code', label: 'Cód. Simpro<br/>Cód. TUSS', simplified: true },
        { key: 'description', label: 'Descrição<br/>Fabricante', simplified: true },
        { key: 'fraction', label: 'Unidade de Medida', simplified: true },
        { key: 'qtde', label: 'Qtde Eq.', simplified: true},
        { key: 'pev', label: 'PMC', simplified: true, monetary: true },
        { key: 'price_ven', label: 'PMC Fração', simplified: true, monetary: true },
        { key: 'pef', label: 'PF', simplified: true, monetary: true },
        { key: 'price_fab', label: 'PF Fração', simplified: true, monetary: true },
        { key: 'hospital', label: 'Hospitalar', simplified: false },
        { key: 'fractionate', label: 'Fracionar', simplified: false }
      ]
    }
  },
  computed: {
    tablefields() {
      const fields = this.type === 'brasindice' ? this.brasindiceFields : this.simproFields

      if (this.isSimplifiedTable) {
        return fields.filter((field) => field.simplified === this.isSimplifiedTable)
      }

      return fields
    }
  },
  methods: {
    parseNumberToMoney,
    getTableReferenceByCD(cd) {
      switch (cd) {
        case '01':
        case '05':
        case '07':
          return '18'
        case '02':
          return '20'
        case '03':
        case '08':
          return '19'
        default:
          return null
      }
    },
    isButtonDisabled(item) {
      return !item.status || item.expense_code === null
    },
    showMoneyField(item) {
      if (typeof item === 'string' && item.includes(',')) {
        const field = item.replace('.', '').replace(',', '.')

        return parseNumberToMoney(parseFloat(field))
      }

      return parseNumberToMoney(item)
    },
    setCD(item, value) {
      this.$set(item, 'expense_code', value)
      this.$set(item, 'table_reference', this.getTableReferenceByCD(value))
    },
    setValue(item, key, value) {
      this.$set(item, key, value)
    },
    emitSave(item) {
      const update = item
      delete update?.effective_date
      this.$emit('save', update)
    }
  },
  watch: {
    table: {
      handler() {
        this.tableItems = this.table
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/table.scss';
  .table {
    background-color: var(--neutral-000);
  }

  thead th {
    vertical-align: middle !important;
  }

  td {
    max-width: 130px !important;
  }

  .multiselect {
    .chevron {
      right: 8px;
      background-color: var(--neutral-000);
    }

    &--disabled {
      .chevron {
        background-color: var(--neutral-70);
      }
    }
  }
</style>
<style lang="scss">
  .table {
    .multiselect__input,
    .multiselect__single,
    .multiselect__option {
      font-size: 14px !important;
    }

    .multiselect--active {
      .multiselect__content-wrapper {
        font-size: 14px !important;
        min-width: 320px !important;
      }
    }

    .multiselect--disabled {
      border-radius: 8px;
    }
  }
</style>
